<template>
  <div v-if="!isFetching" class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
          <h4 class="title">Tabela de Entidades</h4>
        </md-card-header>

        <md-card-content>
          <div class="md-size-100 text-left">
            <md-button
              class="md-raised md-success mt-4"
              @click="
                {
                  (classicModal = true),
                    (modalTitle = 'Nova Entidade'),
                    (buttonModal = 'criar'),
                    (formCriar = true);
                }
              "
            >
              Nova Entidade
            </md-button>
          </div>
          <p></p>
          <md-table
            :value="queriedData"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">Por pagina</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  placeholder="Pesquisar"
                  v-model="searchQuery"
                >
                </md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Id Entidade" md-sort-by="idEntidade">{{
                item.idEntidade
              }}</md-table-cell>
              <md-table-cell
                md-label="Nome Entidade"
                md-sort-by="nomeEntidade"
                >{{ item.nomeEntidade }}</md-table-cell
              >
              <md-table-cell
                md-label="Descrição Entidade"
                md-sort-by="descricaoEntidade"
                >{{ item.descricaoEntidade }}</md-table-cell
              >

              <md-table-cell md-label="Ações">
                <md-button
                  class="md-just-icon md-success md-simple"
                  @click.native="handleEdit(item)"
                  style="margin-right: 15px;"
                >
                  <md-tooltip md-direction="top">Editar</md-tooltip>
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-just-icon md-danger md-simple"
                  @click.native="handleDelete(item)"
                >
                  <md-tooltip md-direction="top">Excluir</md-tooltip>
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class="">
            <p class="card-category">
              Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </pagination>
        </md-card-actions>
      </md-card>
      <template>
        <modal v-if="classicModal" @close="classicModalHide">
          <template slot="header">
            <h4 class="modal-title">{{ modalTitle }}</h4>
            <md-button
              class="md-simple md-just-icon md-round modal-default-button"
              @click="classicModalHide"
            >
              <md-icon>clear</md-icon>
            </md-button>
          </template>

          <template slot="body">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="
                  formCriar === true
                    ? handleSubmit(submit)
                    : handleSubmit(editRow)
                "
              >
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100 md-size-100">
                    <ValidationProvider
                      name="form.nomeEntidade"
                      rules="required|min:3|max:100"
                      v-slot="{ passed, failed, errors }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed }
                        ]"
                      >
                        <label>Nome Entidade</label>
                        <md-input
                          v-model="form.nomeEntidade"
                          type="text"
                        ></md-input>
                      </md-field>
                      <span
                        id="error"
                        class="block text-red-600 text-xs absolute bottom-0 left-0"
                        v-if="errors[0]"
                        >{{ errors[0] }}
                      </span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item md-small-size-100 md-size-100">
                    <ValidationProvider
                      name="form.descricaoEntidade"
                      rules="required|min:3|max:100"
                      v-slot="{ passed, failed, errors }"
                    >
                      <md-field
                        :class="[
                          { 'md-error': failed },
                          { 'md-valid': passed }
                        ]"
                      >
                        <label>Descrição Entidade</label>
                        <md-textarea
                          v-model="form.descricaoEntidade"
                        ></md-textarea>
                      </md-field>
                      <span
                        id="error"
                        class="block text-red-600 text-xs absolute bottom-0 left-0"
                        v-if="errors[0]"
                        >{{ errors[0] }}
                      </span>
                    </ValidationProvider>
                  </div>
                  <div class="md-layout-item md-size-100 text-right">
                    <md-button type="submit" class="md-raised md-success mt-4">
                      {{ buttonModal }}
                    </md-button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </template>
          <template slot="footer"> </template>
        </modal>
      </template>
    </div>
  </div>
</template>

<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { Modal } from "@/components";
import { store } from "../../../store";
import { mapState, mapActions } from "vuex";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";

import { Pagination } from "@/components";
import users from "./users";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import { NOME_CAMPOS } from "@/pages/Dashboard/js/nomeCampos";

var pt_br = pt_BR;
var names = NOME_CAMPOS;
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  components: {
    Pagination,
    Modal
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    user_profile_state: state => state.user_profile,
    reload: function() {
      this.getEntidadeAll();
      return this.queriedData;
    },

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      mensagem: "",
      idEntidade: "",
      formCriar: true,
      buttonModal: "criar",
      modalTitle: "Nova Entidade",
      isFetching: true,
      form: {
        descricaoEntidade: "",
        nomeEntidade: ""
      },
      classicModal: false,
      papel: "",
      currentSort: "nomeEntidade",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      footerTable: ["Nome Entidade", "Descrição Entidade", "Ações"],
      searchQuery: "",
      propsToSearch: ["descricaoEntidade", "nomeEntidade"],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },

  beforeMount() {
    this.getPapel();
    this.getEntidadeAll();
  },
  methods: {
    classicModalHide: function() {
      this.classicModal = false;
      this.form.descricaoEntidade = "";
      this.form.nomeEntidade = "";
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    ...mapActions("entidade", [
      "ActionGetEntidadeAll",
      "ActionPOSTEntidade",
      "ActionDELETEEntidade",
      "ActionPUTEntidade"
    ]),
    async getEntidadeAll() {
      try {
        var papel = this.papel;
        var response = await this.ActionGetEntidadeAll({ papel });
        this.tableData = response.body;
        this.isFetching = false;
        return response.body;
      } catch (err) {
        //console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
        //console.log(err);
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    handleEdit(item) {
      this.formCriar = false;
      this.buttonModal = "Atualizar";
      this.modalTitle = "Editar Entidade";
      this.idEntidade = item.idEntidade;
      this.form.nomeEntidade = item.nomeEntidade;
      this.form.descricaoEntidade = item.descricaoEntidade;
      this.classicModal = true;
    },
    async editRow() {
      try {
        var id = this.idEntidade;
        const newForm = {
          idEntidade: this.idEntidade,
          nomeEntidade: this.form.nomeEntidade,
          descricaoEntidade: this.form.descricaoEntidade
        };
        var papel = this.papel;
        //console.log(newForm);
        var response = await this.ActionPUTEntidade({ id, papel, newForm });
        if (response.status === 200) {
          //console.log(response.body.mensagem);
          this.classicModalHide();
          this.reload;
          this.idEntidade = "";
          Swal.fire({
            icon: "success",
            //title: "Atualizado!",
            text: `${response.body.mensagem}`,
            customClass: {
              confirmButton: "md-button md-success btn-fill"
            },
            buttonsStyling: false
          });
        }
      } catch (err) {
        this.idEntidade = "";
        this.classicModalHide();
        //console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem = "Aplicação temporariamente indisponível.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else if (err.status === 400) {
            this.mensagem = err.body.mensagem;
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    handleDelete(item) {
      const { value: EntidadeNome } = Swal.fire({
        title: "Você tem certeza ?",
        text: `Se você excluir uma Entidade todas os Servidor Publicos, Pensionistas e demais dados referente a esta Entidade serão excluidos ! 
        Isto será irreversível! Favor digitar o Nome da entidade de forma idêntica.`,
        showCancelButton: true,
        input: "text",
        inputValue: "Digite o Nome da Entidade.",
        inputValidator: value => {
          if (!value) {
            return "Você precisa digitar o Nome da Entidade!";
          }
          if (!(value === item.nomeEntidade)) {
            return "O Nome da Entidade não existe ou não confere!";
          }
        },
        customClass: {
          confirmButton: "md-button md-success btn-fill",
          cancelButton: "md-button md-danger btn-fill"
        },
        confirmButtonText: "Sim, excluir!",
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    async deleteRow(item) {
      try {
        var id = item.idEntidade;
        var papel = this.papel;
        var form = this.form;
        var response = await this.ActionDELETEEntidade({ id, papel, form });
        if (response.status === 200) {
          let indexToDelete = this.tableData.findIndex(
            tableRow => tableRow.idEntidade === item.idEntidade
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          this.classicModalHide();
          this.reload;
          Swal.fire({
            icon: "success",
            title: "Excluido!",
            text: `${item.nomeEntidade} ${response.body.mensagem}`,
            customClass: {
              confirmButton: "md-button md-success btn-fill"
            },
            buttonsStyling: false
          });
        }
      } catch (err) {
        this.classicModalHide();
        //console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem = "Aplicação temporariamente indisponível.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else if (err.status === 400) {
            this.mensagem = err.body.mensagem;
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    },
    async submit() {
      try {
        var papel = this.papel;
        var form = this.form;
        var response = await this.ActionPOSTEntidade({ papel, form });
        if (response.status === 200) {
          this.mensagem = response.body.mensagem;
          Swal.fire({
            //title: "Sucesso",
            icon: "success",
            text: this.mensagem,
            customClass: {
              confirmButton: "md-button md-success btn-fill"
            },
            buttonsStyling: false
          });
        }
        this.classicModalHide();
        this.reload;
      } catch (err) {
        this.classicModalHide();
        //console.log(err);
        if (
          err === 400 ||
          err === 500 ||
          err === 404 ||
          err.status === 400 ||
          err.status === 500 ||
          err.status === 404
        ) {
          this.mensagem = "Aplicação temporariamente indisponível.";
        } else if (err.status === 401 || err.data === "Unauthorized") {
          this.mensagem = "Operação não Autorizada";
        } else {
          if (err.body) {
            this.mensagem = err.body.mensagem;
          } else if (err.status === 0) {
            this.mensagem =
              "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
          } else if (err.status === 400) {
            this.mensagem = err.body.mensagem;
          } else {
            this.mensagem =
              "Operação temporariamente indisponível tente mais tarde.";
          }
        }
        Swal.fire({
          title: "Erro!",
          icon: "error",
          text: this.mensagem,
          customClass: {
            confirmButton: "md-button md-danger btn-fill"
          },
          buttonsStyling: false
        });
      }
    }
  },
  mounted() {
    // Fuse search initialization.
    /*this.fuseSearch = new Fuse(this.tableData, {
      keys: ["nomePapel", "descricaoPapel"],
      threshold: 0.3
    });*/
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        this.fuseSearch = new Fuse(result, {
          keys: ["nomeEntidade", "descricaoEntidade"],
          threshold: 0.3
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.fuseSearch = null;
      }
      this.searchedData = result;
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.875rem;
  font-weight: 400;
}
.md-table-cell {
  font-size: 12px;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}
.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-simple,
.md-button.md-simple:hover,
.md-button.md-simple:focus,
.md-button.md-simple:active,
.md-button.md-simple.active,
.md-button.md-simple:active:focus,
.md-button.md-simple:active:hover,
.md-button.md-simple.active:focus,
.md-button.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}
</style>
